<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        :title="`Registration of Tender Briefing - ${this.cards[0].companyName}`"
        :cards="cards"
        v-slot="{ card }"
      >
        <pro-deck-card :title="card.title" :actions="card.actions" expandable>
          <template>
            <div class="col-auto text-bold q-mr-md" style="font-size: 17px">
              {{ getRes("Form.Field.AttendeeList") }}:
            </div>
            <div
              class="row no-wrap"
              v-for="(row, index) in card.attendeeList"
              :key="index"
            >
              <span class="col-auto text-bold q-mr-md" style="font-size: 17px">
                {{ getRes("Form.Field.Attendee") }} {{ index + 1 }}: <br />
              </span>
              <div class="col row">
                <span class="col-4" style="font-size: 17px">
                  {{ getRes("Form.Field.AttendeeName") }}:
                </span>
                <span class="col-8" style="font-size: 17px">
                  {{ row.attendeeName }}<br />
                </span>
                <span class="col-4" style="font-size: 17px"
                  >{{ getRes("Form.Field.PositionTitle") }}:</span
                >
                <span class="col-8" style="font-size: 17px">
                  {{ row.position }}<br />
                </span>
                <span class="col-4" style="font-size: 17px"
                  >{{ getRes("Form.Field.Email") }}:</span
                >
                <span class="col-8" style="font-size: 17px">
                  {{ row.email }}<br />
                </span>
                <span class="col-4" style="font-size: 17px"
                  >{{ getRes("Form.Field.ContactNo") }}.:</span
                >
                <span class="col-8" style="font-size: 17px">
                  {{ row.contactNumber }}<br />
                </span>
                <span
                  class="col-4"
                  style="font-size: 17px"
                  v-if="row.personalId !== null"
                >
                  {{ getRes("Form.Field.HKIDPassportNo") }}:
                </span>
                <span
                  class="col-8"
                  style="font-size: 17px"
                  v-if="row.personalId !== null"
                >
                  {{ row.personalId }}<br />
                </span>
              </div>
            </div>
            <pro-folders
              class="q-ma-md"
              :folders="card.fileList"
              show-download-button
            />
          </template>
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProFolders from "../../../../components/PROSmart/ProFolders";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard, ProFolders },
  data() {
    return {
      cards: [],
      tenderBriefingInfo: {},
      viewFormCode: "",
      editWorkflowProcessCode: "",
    };
  },
  methods: {
    formatDate(date) {
      return this.$proSmart.common.getFormattedDate(new Date(date + "+0800"));
    },
  },
  async created() {
    const getArr = (files) => {
      return files.map((object) => ({
        folderDescription: "",
        ...object,
      }));
    };

    await this.$proSmart.documentUi
      .getTenderBriefingRegistrationByProcurer(
        this,
        this.$route.params.id,
        this.$route.params.tendererId
      )
      .then((info) => {
        info.splice(1);
        this.cards = info.map((submission) => ({
          key: submission.id,
          title: this.$proSmart.common.format.dateTime(
            new Date(submission.submissionDate)
          ),
          tendererId: submission.tendererId,
          companyName: submission.companyName,
          attendeeList: submission.attendeeList,
          submissionDate: submission.submissionDate,
          fileList: getArr(submission.tendererFile.fileList),
        }));
      });
  },
};
</script>
